<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Tree View Toolbar</h3>

	<p>When viewing a framework tree, a small toolbar at the top of the tree pvovides access to a number of togglable view settings:</p>
	<p><img srcset="/docimages/tree_toolbar-1.png 2x" class="k-help-img block"></p>
	<ul style="list-style-type:none">
	<li class="mb-2" style="text-indent:-20px"><v-icon small color="#333" class="mr-1">fas fa-edit</v-icon> Toggle <span v-html="link('editing', '“edit mode”')"></span> on/off. <div style="font-size:0.9em; line-height:1.4em; text-indent:0px;"><b>Note:</b> you will only see this button if you are signed in to <span v-html="app_name"/> and have <span v-html="link('roles_framework', 'editing rights')"></span> to the framework.</div></li>
	<li class="mb-2" style="text-indent:-20px"><v-icon small color="#333" class="mr-1">fas fa-comment</v-icon> Toggle <span v-html="link('add_comments', '“comment mode”')"></span> on/off. <div style="font-size:0.9em; line-height:1.4em; text-indent:0px;"><b>Note:</b> you will only see this button if you are signed in to <span v-html="app_name"/>.</div></li>
	<li class="mb-2" style="text-indent:-20px"><v-icon small color="#333" class="mr-1">fas fa-arrows-alt-h</v-icon> Choose which sets of <span v-html="link('case_associations', 'associations')"></span> you wish to see as you browse the framework in <span v-html="app_name"/>.</li>
	<li class="mb-2" style="text-indent:-20px"><v-icon small color="#333" class="mr-1" style="transform:rotate(90deg)">fas fa-turn-down</v-icon> Toggle whether or not to wrap item statements in the tree, as illustrated in the screenshot below.<img srcset="/docimages/tree_toolbar-2.png 3x" class="k-help-img block"></li>
	<li class="mb-2" style="text-indent:-20px"><v-icon small color="#333" class="mr-1">fas fa-angles-up</v-icon> Collapse all open “branches” in the tree.</li>
	<li class="mb-2" style="text-indent:-20px"><v-icon small color="#333" class="mr-1">fas fa-id-card</v-icon> Show or hide <span v-html="link('identifiers', 'identifiers (GUIDs)')"></span> in item cards.</li>
	<li class="mb-2" style="text-indent:-20px"><v-icon small color="#333" class="mr-1">fas fa-table-cells-large</v-icon> Switch to the <span v-html="link('tileview', '“Tile View”')"></span> of the framework.</li>
	<li class="mb-2" style="text-indent:-20px"><v-icon small color="#333" class="mr-1">fas fa-table-list</v-icon> Switch to the <span v-html="link('tableitemsview', '“Table View”')"></span> of the framework.<div style="font-size:0.9em; line-height:1.4em; text-indent:0px;"><b>Note:</b> The table view is not available on phone-sized screens.</div></li>
	<li class="mb-2" style="text-indent:-20px"><v-icon small color="#333" class="mr-1">fas fa-info-circle</v-icon> View this help document!</li>
	</ul>

</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	